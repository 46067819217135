import React, { useState, useEffect, useContext, useRef } from "react";
import SegmentBuilder from "./../SegmentBuilder";
import SegmentsDropdown from "./../SegmentsDropdown";
import { UserDataContext } from "./../../app/UserData";
import { SegmentsContext } from "./../../../context/Segments";
import { ReducerContext } from "../../../reducer/context";
import {
  OPEN_CONFIRM,
  CLOSE_CONFIRM,
  CONFIRM_BUSY,
  CONFIRM_IDLE,
} from "../../../reducer";
import { deleteSegmentById } from "../../../lib/firebase/segment";
import DatePicker from "./../DatePicker";
import SegmentsDrawer from "./../../app/SegmentsDrawer";
import SegmentButton from "./../../app/SegmentButton";
import { BiCalendar } from "react-icons/bi";
import "./style.scss";
import useFetchSegments from "../../../hooks/useFetchSegments";
import Alert from "../../share/Alert";
import { FaTimes } from "react-icons/fa";
import { Button } from "../../share/InsightechUI";
import InsightechIcon from "../../share/InsightechIcon";
import {
  conditionFixture,
  includedPageContainersFixture,
  excludedPageContainersFixture,
} from "../../../fixtures/filters";
import { deepCopy } from "../../../utils";

function QuickFilter({ changeSegmentPanelState, ...props }) {
  const { showCompareMode, type } = props;
  const {
    activeProfileId,
    setReloadApi,
    initialDates,
    setStartDate,
    setSelectedStartDate,
    setSelectedEndDate,
    setEndDate,
    setMySelectedDates,
    setUserConditions,
    setIncludedPageContainers,
    setExcludedPageContainers,
    setAppliedFilters,
    setCachedFilters,
    setQueryId,
    isDateCompareOn,
    setIsDateCompareOn,
    selectedDateRange,
    setSelectedDateRange,
    compareStartDate,
    compareEndDate,
    setCompareStartDate,
    setCompareEndDate,
    isSegmentCompareOn,
    setIsSegmentCompareOn,
    crossFilterMsg,
    setCrossFilterMsg,
    setOnlyRecordedSessions,
  } = useContext(UserDataContext);
  const { dispatch } = useContext(ReducerContext);
  const {
    defaultSegments,
    selectedSegments,
    setSelectedSegments,
    unselectedSegments,
    setUnselectedSegments,
    segments,
    setSegments,
    comparedSelectedSegments,
    setComparedSelectedSegments,
  } = useContext(SegmentsContext);

  const { segmentsList, setSegmentsList } = useFetchSegments(activeProfileId);
  const [isSegmentPanelVisible, setIsSegmentPanelVisible] = useState(false);
  const [focusedSegmentId, setFocusedSegmentId] = useState(null);

  const [comparedRangeLabel, setComparedRangeLabel] = useState(null);
  const formattedCompareStartDate = compareStartDate?.format("YYYY-MM-DD");
  const formattedCompareEndDate = compareEndDate?.format("YYYY-MM-DD");

  const _isMounted = useRef(true);

  useEffect(() => {
    if (isSegmentPanelVisible) {
      document.body.classList.add("locked");
    } else {
      document.body.classList.remove("locked");
    }
  }, [isSegmentPanelVisible]);

  // Cleanup
  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickReset = () => {
    // reset compare mode
    setIsDateCompareOn(false);
    setCompareStartDate(null);
    setCompareEndDate(null);
    setIsSegmentCompareOn(false);
    setComparedSelectedSegments({});

    // reset recordedSessions (Replay List)
    setOnlyRecordedSessions(false);

    //reset date range
    setSelectedStartDate(initialDates.startDate);
    setStartDate(initialDates.startDate);
    setSelectedEndDate(initialDates.endDate);
    setMySelectedDates({
      startDate: initialDates.startDate,
      endDate: initialDates.endDate,
    });
    setEndDate(initialDates.endDate);
    setSelectedDateRange(initialDates);

    //reset filters
    setUserConditions([deepCopy(conditionFixture)]);
    setIncludedPageContainers(deepCopy(includedPageContainersFixture));
    setExcludedPageContainers(deepCopy(excludedPageContainersFixture));
    setAppliedFilters({});
    setCachedFilters({});

    //reset segments
    setSelectedSegments({ ...defaultSegments });

    // apply reset
    setReloadApi(true);
    setQueryId(null);
  };

  const handleClickSegment = (id) => {
    setFocusedSegmentId(id);
    setIsSegmentPanelVisible(true);
  };

  // delete button handler
  const handleUnselectSegment = (id) => {
    const updatedSegments = { ...selectedSegments };
    delete updatedSegments[id];
    setSelectedSegments({
      ...updatedSegments,
    });
    setReloadApi(true);

    if (segments[id].default) {
      setUnselectedSegments({
        ...unselectedSegments,
        [id]: true,
      });
    }
  };

  // delete button handler
  const handleUnselectComparedSegment = (id) => {
    const updatedSegments = { ...comparedSelectedSegments };
    delete updatedSegments[id];
    setComparedSelectedSegments({
      ...updatedSegments,
    });
    setReloadApi(true);
  };

  const handleCloseSegmentDrawer = () => {
    setIsSegmentPanelVisible(false);
  };

  const handleLoadSegmentDrawer = () => {
    setIsSegmentPanelVisible(true);
  };

  // delete segment on the drawer
  const handleClickDelete = (e) => {
    const segmentId = focusedSegmentId;

    dispatch({
      type: OPEN_CONFIRM,
      confirmConfig: {
        title: "Confirm Action",
        text: "Are you sure you want to delete this segment?",
        confirmText: "Delete Segment",
        confirmAction: () => {
          dispatch({ type: CONFIRM_BUSY });
          deleteSegmentById(activeProfileId, segmentId).then(() => {
            dispatch({ type: CONFIRM_IDLE });
            dispatch({ type: CLOSE_CONFIRM });
            setIsSegmentPanelVisible(false);
            setFocusedSegmentId(null);

            // remove segment from segments list
            const updatedSegments = { ...segments };
            delete updatedSegments[segmentId];
            setSegments({ ...updatedSegments });

            // remove segment from selected segments
            const updatedSelectSegments = { ...selectedSegments };
            delete updatedSelectSegments[segmentId];
            setSelectedSegments({
              ...updatedSelectSegments,
            });
            // refresh data
            setReloadApi(true);
          });
        },
      },
    });
    return false;
  };

  const handleDeleteCompareDate = () => {
    setIsDateCompareOn(false);
    setCompareStartDate(null);
    setCompareEndDate(null);
  };

  const getCompareToButtonLabel = () => {
    if (compareStartDate && compareEndDate) {
      let label = `${compareStartDate.format(
        "MMM D, YYYY"
      )} - ${compareEndDate.format("MMM D, YYYY")}`;

      setComparedRangeLabel(label);
    } else {
      setComparedRangeLabel(null);
    }
  };

  // update button label when new compare date applied
  useEffect(() => {
    getCompareToButtonLabel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedCompareStartDate, formattedCompareEndDate]);

  // set timer to clear cross filter message
  useEffect(() => {
    if (!!crossFilterMsg) {
      const timer = setTimeout(() => {
        setCrossFilterMsg("");
      }, 3000);

      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crossFilterMsg]);

  return (
    <div className="filters-group">
      <div className="filters-group-row">
        <div className="group-row-left">
          <DatePicker
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            showCompareMode={showCompareMode}
            isCompareDisabled={isSegmentCompareOn}
            setComparedRangeLabel={setComparedRangeLabel}
          />

          <SegmentBuilder segments={segments} setSegments={setSegments} />

          <div className="form-row align-items-center segment-selector">
            <div className="mr-auto segments-col">
              <SegmentsDropdown
                showCompareMode={showCompareMode}
                setReloadApi={setReloadApi}
                handleClickSegment={handleLoadSegmentDrawer}
                segments={segments}
                selectedSegments={selectedSegments}
                setSelectedSegments={setSelectedSegments}
                comparedSelectedSegments={comparedSelectedSegments}
                setComparedSelectedSegments={setComparedSelectedSegments}
                setIsSegmentPanelVisible={setIsSegmentPanelVisible}
                setFocusedSegmentId={setFocusedSegmentId}
                isCompareDisabled={isDateCompareOn}
                showEditSegments={true}
              />
            </div>
          </div>

          {Object.keys(selectedSegments).map((key) => {
            const segment = segments[key];
            return segment ? (
              <SegmentButton
                handleClick={handleClickSegment}
                handleClickDelete={handleUnselectSegment}
                key={segment.id}
                id={segment.id}
                label={segment.name}
                className={"btn-success"}
              />
            ) : null;
          })}
        </div>

        {type !== "report" && (
          <Button size="chip" color="plain-default" onClick={handleClickReset}>
            <InsightechIcon name="Reset" />
            Reset All
          </Button>
        )}
      </div>

      {showCompareMode && isDateCompareOn && !!comparedRangeLabel && (
        <div className="filters-group-row">
          <div className="group-row-left">
            <span>Compare To:</span>
            <button className="btn-chips white-smoke">
              <span className="text">
                <BiCalendar
                  size={20}
                  color="#EF9E00"
                  style={{ marginTop: "-3", marginRight: "9px" }}
                />
                <span>{comparedRangeLabel}</span>
              </span>
              <span className="icon" onClick={handleDeleteCompareDate}>
                <FaTimes />
              </span>
            </button>
          </div>
        </div>
      )}

      {showCompareMode && isSegmentCompareOn && (
        <div className="filters-group-row">
          <div className="group-row-left">
            <span className="mt-1">Compare To:</span>
            {Object.keys(comparedSelectedSegments).map((key) => {
              const segment = segments[key];
              return segment ? (
                <SegmentButton
                  className={"dark-orange"}
                  handleClick={handleClickSegment}
                  handleClickDelete={handleUnselectComparedSegment}
                  key={segment.id}
                  id={segment.id}
                  label={segment.name}
                />
              ) : null;
            })}
            {!Object.keys(comparedSelectedSegments).length > 0 && (
              <span className="mt-1">All Traffic (no segment selected)</span>
            )}
          </div>
        </div>
      )}

      {!!crossFilterMsg && (
        <div className="mt-1 mb-n4 ml-n2 mr-n1">
          <Alert
            show={true}
            type={"primary"}
            message={crossFilterMsg}
            allowClose={true}
          />
        </div>
      )}

      {isSegmentPanelVisible && (
        <SegmentsDrawer
          setFocusedSegmentId={setFocusedSegmentId}
          segmentId={focusedSegmentId}
          isVisible={isSegmentPanelVisible}
          setIsVisible={setIsSegmentPanelVisible}
          handleClose={handleCloseSegmentDrawer}
          setSegments={setSegments}
          segments={segments}
          setListData={setSegmentsList}
          listData={segmentsList}
          handleClickDelete={handleClickDelete}
        />
      )}
    </div>
  );
}

export default QuickFilter;

import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-dates/lib/css/_datepicker.css";
import { dateRanges } from "./../../../helpers/dateRange";
import { Button, Switch } from "./../../share/InsightUI";
import { UserDataContext } from "./../../app/UserData";
import { DISABLED_DATE_COMPARISON } from "../../../constants/info";
import { BiCalendar } from "react-icons/bi";
import { FaChevronUp, FaChevronDown, FaRegHandPointer } from "react-icons/fa";
import moment from "moment";
import "react-day-picker/lib/style.css";
import "./style.scss";

function DatePicker({ dropdownAlignment = "left", ...props }) {
  const {
    setSelectedStartDate,
    setSelectedEndDate,
    selectedStartDate,
    setStartDate,
    selectedEndDate,
    setEndDate,
    setReloadApi,
    mySelectedDates,
    setMySelectedDates,
    isDateCompareOn,
    setIsDateCompareOn,
    compareStartDate,
    compareEndDate,
    setCompareStartDate,
    setCompareEndDate,
  } = useContext(UserDataContext);
  const {
    customSelectedDates,
    customSetSelectedDates,
    selectedDateRange,
    setSelectedDateRange,
    showCompareMode,
    isCompareDisabled,
  } = props;

  const [isCalendarDropdownVisible, setIsCalendarDropdownVisible] =
    useState(false);
  const dropdownRef = useRef(null);
  const datePickerRef = useRef(null);
  // customSelectedDates: set from replay viewer filter drawer
  // mySelectedDates: context level state
  let selectedDates, setSelectedDates;
  if (customSetSelectedDates && customSetSelectedDates) {
    selectedDates = customSelectedDates;
    setSelectedDates = customSetSelectedDates;
  } else {
    selectedDates = mySelectedDates;
    setSelectedDates = setMySelectedDates;
  }

  // Date Comparison
  const rangeNullable = { startDate: null, endDate: null };
  const [isAllRangesValid, setIsAllRangesValid] = useState(true);
  const [rangeB, setRangeB] = useState(rangeNullable);
  const [lastDayMouseEnter, setLastDayMouseEnter] = useState(null);
  const [isSwitchOn, setIsSwitchOn] = useState(isDateCompareOn);
  const [datePickerButtonLabel, setDatePickerButtonLabel] = useState(
    selectedDateRange.label &&
      selectedDateRange.label.toLowerCase() === "custom"
      ? selectedDates.startDate && selectedDates.endDate
        ? `${selectedDates.startDate.format("MMM D, YYYY")}
            -
            ${selectedDates.endDate.format("MMM D, YYYY")}
            `
        : "Pick a date"
      : selectedDateRange.label
  );

  // Setup click events
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsCalendarDropdownVisible(false);
      }
    };

    // Add outside click handler
    document.addEventListener("mousedown", handleClickOutside);

    // Remove outside click handler on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDropdownToggle = () => {
    setIsCalendarDropdownVisible(!isCalendarDropdownVisible);
  };

  const handleDateCompare = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleCalendarDateChange = (day, modifiers = {}) => {
    const range = DateUtils.addDayToRange(day, {
      from: selectedDates.startDate.toDate(),
      to: selectedDates.endDate.toDate(),
    });
    if (modifiers.disabled) {
      return;
    }
    setSelectedDateRange(dateRanges["custom"]);
    setSelectedDates({
      startDate: moment(range.from).startOf("day"),
      endDate: moment(range.to).endOf("day"),
    });
  };

  const handleSelectPresetDate = (id) => {
    if (isDateCompareOn && isSwitchOn) {
      setRangeB(
        getPrecedingDateRange({
          startDate: dateRanges[id].startDate,
          endDate: dateRanges[id].endDate,
        })
      );
    }
    setSelectedDateRange(dateRanges[id]);
    setSelectedDates({
      startDate: dateRanges[id].startDate,
      endDate: dateRanges[id].endDate,
    });

    datePickerRef.current.showMonth(
      moment(dateRanges[id].endDate).subtract(1, "months").toDate()
    );
  };

  // update date range in calendar button label
  const matchDateBtnLabel = () => {
    if (showCompareMode && isDateCompareOn) {
      let label =
        selectedStartDate || selectedEndDate
          ? `${moment(selectedStartDate).format("MMM D, YYYY")} - ${moment(
              selectedEndDate
            ).format("MMM D, YYYY")}`
          : "Pick a date";
      setDatePickerButtonLabel(label);
    } else {
      let label =
        selectedDateRange.label &&
        selectedDateRange.label.toLowerCase() === "custom"
          ? `${selectedStartDate.format("MMM D, YYYY")}
            -
            ${selectedEndDate.format("MMM D, YYYY")}
            `
          : selectedDateRange.label;
      setDatePickerButtonLabel(label);
    }
  };

  const setAddDayToRange = (day, range, setRange) => {
    const rg = DateUtils.addDayToRange(day, {
      from: range.startDate?.toDate(),
      to: range.endDate?.toDate(),
    });
    setRange({
      startDate: rg.from ? moment(rg.from).startOf("day") : null,
      endDate: rg.to ? moment(rg.to).endOf("day") : null,
    });
  };

  const increaseSmallerRange = (tempRange, range) => {
    let shouldIncrease = false;
    let increasedRange;
    const isFromInTempRange = DateUtils.isDayInRange(
      range.startDate.toDate(),
      tempRange
    );
    const isToInTempRange = DateUtils.isDayInRange(
      range.endDate.toDate(),
      tempRange
    );
    if (isFromInTempRange && isToInTempRange) {
      shouldIncrease = true;
      increasedRange = {
        startDate: moment(tempRange.from).startOf("day"),
        endDate: moment(tempRange.to).endOf("day"),
      };
    } else {
      increasedRange = { range };
    }
    return { shouldIncrease, increasedRange };
  };

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const isRangeIncreaseOrUpdate = ({
    day,
    checkRange,
    setIncrease,
    setUpdate,
    updateRange,
  }) => {
    const { shouldIncrease, increasedRange } = increaseSmallerRange(
      { from: updateRange.startDate.toDate(), to: day },
      checkRange
    );
    if (shouldIncrease) {
      setIncrease(increasedRange);
      setUpdate(rangeNullable);
    } else {
      setAddDayToRange(day, updateRange, setUpdate);
    }
  };

  const handleCompareCalendarChanges = (day) => {
    const isDayInSelectedRanges =
      DateUtils.isDayInRange(day, {
        from: selectedDates.startDate?.toDate(),
        to: selectedDates.endDate?.toDate(),
      }) ||
      DateUtils.isDayInRange(day, {
        from: rangeB.startDate?.toDate(),
        to: rangeB.endDate?.toDate(),
      });
    let isDayEqualFrom = false;
    if (
      selectedDates.startDate &&
      !selectedDates.endDate &&
      DateUtils.isSameDay(day, selectedDates.startDate.toDate())
    ) {
      isDayEqualFrom = true;
    }
    if (
      rangeB.startDate &&
      !rangeB.endDate &&
      DateUtils.isSameDay(day, rangeB.startDate.toDate())
    ) {
      isDayEqualFrom = true;
    }
    // clear range
    if (
      selectedDates.startDate &&
      selectedDates.endDate &&
      DateUtils.isDayInRange(day, {
        from: selectedDates.startDate.toDate(),
        to: selectedDates.endDate.toDate(),
      })
    ) {
      setSelectedDateRange(dateRanges["custom"]);
      setSelectedDates(rangeNullable);
      setLastDayMouseEnter(null);
      if (rangeB.startDate && !rangeB.endDate) {
        setRangeB(rangeNullable);
      }
    }
    if (
      rangeB.startDate &&
      rangeB.endDate &&
      DateUtils.isDayInRange(day, {
        from: rangeB.startDate.toDate(),
        to: rangeB.endDate.toDate(),
      })
    ) {
      setRangeB(rangeNullable);
      setLastDayMouseEnter(null);
      if (selectedDates.startDate && !selectedDates.endDate) {
        setSelectedDateRange(dateRanges["custom"]);
        setSelectedDates(rangeNullable);
      }
    }
    // set day to range
    if (
      (!selectedDates.startDate || !selectedDates.endDate) &&
      (!isDayInSelectedRanges || isDayEqualFrom)
    ) {
      if (selectedDates.startDate && rangeB.startDate && rangeB.endDate) {
        isRangeIncreaseOrUpdate({
          day: day,
          checkRange: rangeB,
          setIncrease: setRangeB,
          setUpdate: setSelectedDates,
          updateRange: selectedDates,
        });
      } else {
        setAddDayToRange(day, selectedDates, setSelectedDates);
      }
    } else if (
      (!rangeB.startDate || !rangeB.endDate) &&
      (!isDayInSelectedRanges || isDayEqualFrom)
    ) {
      if (rangeB.startDate) {
        isRangeIncreaseOrUpdate({
          day: day,
          checkRange: selectedDates,
          setIncrease: setSelectedDates,
          setUpdate: setRangeB,
          updateRange: rangeB,
        });
      } else {
        setAddDayToRange(day, rangeB, setRangeB);
      }
    }
  };

  const handleDayMouseEnter = (day) => {
    if (
      !isSelectingFirstDay(
        selectedDates.startDate?.toDate(),
        selectedDates.endDate?.toDate(),
        day
      ) ||
      !isSelectingFirstDay(
        rangeB.startDate?.toDate(),
        rangeB.endDate?.toDate(),
        day
      )
    ) {
      setLastDayMouseEnter(day);
    }
  };

  const handleApplyDateRange = () => {
    setSelectedStartDate(selectedDates.startDate);
    setSelectedEndDate(selectedDates.endDate);
    setIsCalendarDropdownVisible(false);
    setStartDate(selectedDates.startDate);
    setEndDate(selectedDates.endDate);
    setReloadApi(true);
    setIsDateCompareOn(isSwitchOn);
    if (isSwitchOn) {
      setCompareStartDate(rangeB.startDate);
      setCompareEndDate(rangeB.endDate);
    } else {
      setCompareStartDate(null);
      setCompareEndDate(null);
    }
  };

  let alignmentClass = "dropdown-menu-md-right";
  switch (dropdownAlignment) {
    case "left":
      alignmentClass = "dropdown-menu-md-left";
      break;
    default:
      break;
  }

  const styleCompareRanges = {
    selectedDates: { backgroundColor: "#4E73DF" },
    rangeB: { backgroundColor: "#EF9E00" },
  };

  const getPrecedingDateRange = (range) => {
    const startDate = new Date(range.startDate.toDate());
    const endDate = new Date(range.endDate.toDate());
    let duration =
      Math.floor(
        (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
      ) + 1;
    const compareEnd = new Date(startDate - 1);
    const compareEndDate = moment(compareEnd).endOf("day");
    const compareStart = new Date(startDate - 1000 * 3600 * 24 * duration);
    const compareStartDate = moment(compareStart).startOf("day");
    return { startDate: compareStartDate, endDate: compareEndDate };
  };

  // set button label if date changes applied
  useEffect(() => {
    matchDateBtnLabel();

    // update calendar highlight if date changes applied (redirect from report)
    setSelectedDates({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate]);

  // set range B (calendar highlight) if compare date changes from external: report
  useEffect(() => {
    if (isDateCompareOn && compareStartDate && compareEndDate) {
      setRangeB({ startDate: compareStartDate, endDate: compareEndDate });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareStartDate, compareEndDate]);

  // Compare switch:
  // Revert back to the applied date when no select
  // auto select range B
  useEffect(() => {
    if (selectedDates.startDate && selectedDates.endDate) {
      if (
        !selectedDates.startDate.isValid() ||
        !selectedDates.endDate.isValid()
      ) {
        setSelectedDates({
          startDate: selectedStartDate,
          endDate: selectedEndDate,
        });
      }
    } else {
      setSelectedDates({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      });
    }

    if (isSwitchOn && !compareStartDate && !compareEndDate) {
      setRangeB(getPrecedingDateRange(selectedDates));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSwitchOn]);

  // Compare mode off: clean and set
  useEffect(() => {
    setIsSwitchOn(isDateCompareOn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDateCompareOn]);

  // Revert back date & compare mode when close dropdown
  useEffect(() => {
    if (!isCalendarDropdownVisible) {
      setIsSwitchOn(isDateCompareOn);
      setSelectedDates({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      });

      if (isDateCompareOn) {
        setRangeB({ startDate: compareStartDate, endDate: compareEndDate });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalendarDropdownVisible]);

  // check if ranges invalid to disable Apply button
  useEffect(() => {
    if (isSwitchOn) {
      if (
        selectedDates.startDate &&
        selectedDates.endDate &&
        rangeB.startDate &&
        rangeB.endDate
      ) {
        setIsAllRangesValid(true);
      } else {
        setIsAllRangesValid(false);
      }
    } else {
      if (selectedDates.startDate && selectedDates.endDate) {
        setIsAllRangesValid(
          selectedDates.startDate.isValid() && selectedDates.endDate.isValid()
        );
      } else {
        setIsAllRangesValid(false);
      }
    }
  }, [selectedDates, rangeB, isSwitchOn]);

  // clear mouse enter range b
  useEffect(() => {
    if (rangeB.startDate && rangeB.endDate) {
      setLastDayMouseEnter(null);
    }
  }, [rangeB]);

  // clear mouse enter & update range B highlight
  useEffect(() => {
    if (
      isSwitchOn &&
      selectedDates.startDate &&
      selectedDates.endDate &&
      !compareStartDate &&
      !compareEndDate
    ) {
      setLastDayMouseEnter(null);
      setRangeB(getPrecedingDateRange(selectedDates));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates]);

  return (
    <div className="datepicker-dropdown" ref={dropdownRef}>
      <button
        className="btn-grey"
        id="date-range-dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={handleDropdownToggle}
      >
        <span className="icon">
          <BiCalendar
            color={showCompareMode && isDateCompareOn ? "#4E73DF" : ""}
          />
        </span>

        {datePickerButtonLabel}
        <span className="chevron-icon">
          {isCalendarDropdownVisible ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </button>
      <div
        className={`${alignmentClass} dropdown-menu shadow animated--grow-in ${
          isCalendarDropdownVisible && "show"
        }`}
        aria-labelledby="date-range-dropdown"
      >
        <div className="calendar-col">
          {((showCompareMode && !isSwitchOn) || !showCompareMode) && (
            <>
              <DayPicker
                ref={datePickerRef}
                numberOfMonths={2}
                initialMonth={moment().subtract(1, "months").toDate()}
                onDayClick={handleCalendarDateChange}
                selectedDays={{
                  from: selectedDates.startDate?.toDate() || null,
                  to: selectedDates.endDate?.toDate() || null,
                }}
                modifiers={{
                  from: selectedDates.startDate?.toDate() || null,
                  to: selectedDates.endDate?.toDate() || null,
                }}
                enableOutsideDaysClick={true}
              />
              <div className="calendar-footer mb-1">
                <p>
                  You picked:{" "}
                  <span
                    className={`${
                      !isAllRangesValid ? "text-danger" : "text-secondary"
                    }`}
                  >
                    {selectedDateRange.label &&
                    selectedDateRange.label.toLowerCase() === "custom"
                      ? ` ${selectedDates.startDate?.format(
                          "MMM D, YYYY"
                        )} - ${selectedDates.endDate?.format("MMM D, YYYY")}`
                      : selectedDateRange.label}
                  </span>
                  .
                </p>
              </div>
            </>
          )}
          {showCompareMode && isSwitchOn && (
            <>
              <DayPicker
                ref={datePickerRef}
                numberOfMonths={2}
                initialMonth={moment().subtract(1, "months").toDate()}
                onDayClick={handleCompareCalendarChanges}
                onDayMouseEnter={handleDayMouseEnter}
                selectedDays={[
                  {
                    from: selectedDates.startDate?.toDate() || null,
                    to: selectedDates.endDate?.toDate() || lastDayMouseEnter,
                  },
                  {
                    from: rangeB.startDate?.toDate() || null,
                    to: rangeB.endDate?.toDate() || lastDayMouseEnter,
                  },
                ]}
                modifiers={{
                  selectedDates: {
                    from: selectedDates.startDate?.toDate(),
                    to: selectedDates.endDate?.toDate(),
                  },
                  rangeB: {
                    from: rangeB.startDate?.toDate(),
                    to: rangeB.endDate?.toDate(),
                  },
                }}
                modifiersStyles={{
                  selectedDates: styleCompareRanges.selectedDates,
                  rangeB: styleCompareRanges.rangeB,
                }}
              />
              <div className="calendar-footer">
                <p>
                  <span className="strip strip-blue mr-2"></span>
                  Date Range
                  {selectedDates.startDate && selectedDates.endDate ? (
                    ``
                  ) : (
                    <span className="text-danger">: Pick a date</span>
                  )}
                </p>
                <p>
                  <span className="strip strip-orange mr-2"></span>
                  Comparison
                  {rangeB.startDate && rangeB.endDate ? (
                    ``
                  ) : (
                    <span className="text-danger">: Pick a date</span>
                  )}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="date-presets-col d-flex flex-column justify-content-center">
          {Object.keys(dateRanges).map((key) => {
            const range = dateRanges[key];
            if (key === "custom") return null;
            return (
              <DateButton
                selected={selectedDateRange.id === key}
                label={range.label}
                handleDateSelect={handleSelectPresetDate}
                key={key}
                id={key}
                startDate={range.startDate}
                endDate={range.endDate}
              />
            );
          })}
          <div className="dropdown-divider"></div>
          {showCompareMode && (
            <Fragment>
              <div className="d-flex justify-content-between align-items-center ml-1">
                <label htmlFor="compare" className="mt-2">
                  Compare
                </label>
                <Switch
                  variant="compare-switch"
                  name="compare-date"
                  id="compare-date"
                  isChecked={isSwitchOn}
                  onChange={handleDateCompare}
                  disabled={isCompareDisabled}
                  tooltip={isCompareDisabled ? DISABLED_DATE_COMPARISON : null}
                />
              </div>
              {isSwitchOn &&
                selectedDates.startDate &&
                selectedDates.endDate &&
                rangeB.startDate &&
                rangeB.endDate && (
                  <div className="compare-tip">
                    <FaRegHandPointer className="mt-n1 mr-1" />
                    Click a date range to edit
                  </div>
                )}
              <div className="dropdown-divider"></div>
            </Fragment>
          )}
          <Button
            variant="primary"
            size="small"
            style={{ width: "100%" }}
            onClick={handleApplyDateRange}
            disabled={!isAllRangesValid}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

function DateButton(props) {
  function onClick(e) {
    props.handleDateSelect(props.id);
  }
  return (
    <button
      className={`dropdown-item ${props.selected ? "selected" : ""}`}
      onClick={onClick}
    >
      {props.label}
    </button>
  );
}

DatePicker.propTypes = {
  initialValue: PropTypes.object,
  customSelectedDates: PropTypes.object,
  customSetSelectedDates: PropTypes.func,
  selectedDateRange: PropTypes.object,
  setSelectedDateRange: PropTypes.func,
};
DatePicker.defaultProps = {};

export default DatePicker;

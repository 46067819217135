import * as Sentry from "@sentry/react";
import {
  DUMMY_TITLE_LITE_OFF,
  DUMMY_TITLE_LITE_OFF_INTERACTIONS,
  DUMMY_TITLE_LITE_ON,
  DUMMY_PARAGRAPH_LITE_OFF,
  DUMMY_PARAGRAPH_LITE_OFF_INTERACTIONS,
  DUMMY_PARAGRAPH_LITE_ON,
  DUMMY_FOOTER,
} from "../constants/replay";

export const bubbleTagNameByPath = (elementPath, tagName) => {
  var result = false;
  var parts = elementPath.split("/");
  for (var i = 0; i < parts.length; i++) {
    var part = parts[i];
    if (part.indexOf("[") >= 0) part = part.substring(0, part.indexOf("["));
    if (part === tagName) {
      result = true;
      break;
    }
  }
  return result;
};

export const getBubbleTagPath = (elementPath, tagName) => {
  var result = "";
  var parts = elementPath.split("/");
  var tagPath = "";
  for (var i = 0; i < parts.length; i++) {
    var part = parts[i];
    if (i > 0) {
      tagPath = tagPath + "/";
    }
    tagPath = tagPath + part;
    if (part.indexOf("[") >= 0) part = part.substring(0, part.indexOf("["));
    if (part === tagName) {
      result = tagPath;
      break;
    }
  }
  return result;
};

const isInShadow = function (node) {
  return (
    node.getRootNode().nodeType === Node.DOCUMENT_FRAGMENT_NODE &&
    node.getRootNode().host !== undefined
  );
};

export const getCSSPath = function (el) {
  //if (!(el instanceof Element)) return;
  if (
    typeof el === "undefined" ||
    (el.nodeType !== Node.ELEMENT_NODE &&
      el.nodeType !== Node.DOCUMENT_FRAGMENT_NODE)
  )
    return;
  var path = [];
  while (
    el !== null &&
    (el.nodeType === Node.ELEMENT_NODE ||
      el.nodeType === Node.DOCUMENT_FRAGMENT_NODE)
  ) {
    var selector = el.nodeName.toLowerCase();
    var parent = null;

    if (Node.DOCUMENT_FRAGMENT_NODE === el.nodeType) {
      if (el.host && el.mode === "open") {
        selector = "shadow-root";
      }
      // Next iteration on host element
      parent = el.host;
    } else {
      if (selector !== "html" && selector !== "head" && selector !== "body") {
        parent = el.parentNode;
        if (el.id && typeof el.id === "string") {
          selector = "#" + el.id; // use ID only
          parent = isInShadow(el) ? parent : false;
        } else if (
          el.getAttribute("id") &&
          typeof el.getAttribute("id") === "string" &&
          el.getAttribute("id").trim() !== ""
        ) {
          selector = "#" + el.getAttribute("id"); // use ID only
          parent = isInShadow(el) ? parent : false;
        } else if (el.parentNode) {
          var onlyElement = true;
          var uniqueClass = true;
          var classNames = Array.from(el.classList)
          if (classNames.length > 0) {
            // Handle internal Insightech classes
            if (classNames.indexOf("InsightechMouseHover") >= 0) {
              classNames.splice(classNames.indexOf("InsightechMouseHover"), 1);
            }
            if (classNames.indexOf("InsightechMouseFocus") >= 0) {
              classNames.splice(classNames.indexOf("InsightechMouseFocus"), 1);
            }
            if (classNames.indexOf("insightechInspection") >= 0) {
              classNames.splice(classNames.indexOf("insightechInspection"), 1);
            }
          } else {
            uniqueClass = false;
          }
          if (classNames.length === 0) {
            uniqueClass = false;
          }
          var nth = 1;
          for (var i = 0; i < el.parentNode.children.length; i++) {
            const child = el.parentNode.children[i];
            if (child === el) {
              nth += i;
            } else if (child.nodeName === el.nodeName) {
              onlyElement = false; // there is another element with the same tag name
              if (uniqueClass) { // See if we can use the class names as unique selector
                if(child.classList.length > 0) {
                    var allFound = true; // Assume another element has the same class names, try to prove it wrong
                    for(var c=0; c < classNames.length; c++){
                        if(!child.classList.contains(classNames[c])){
                            allFound = false;
                            break; // Found one difference, stop looking
                        }
                    }
                    if(allFound){
                        // if all class names are found in another element, the class name is not unqiue
                        uniqueClass = false;
                    }
                }
              }
            }
          }
          if (onlyElement) {
            // eslint-disable-next-line no-self-assign
            selector = selector;
          } else if (uniqueClass) {
            selector += "." + classNames.filter(Boolean).join(".");
          } else {
            selector += ":nth-child(" + nth + ")";
          }
        }
      } else {
        parent = false;
      }
    }
    path.unshift(selector);
    if (parent) {
      el = parent;
    } else {
      break;
    }
  }
  return path.join(" > ");
};

// Transform IDs & classnames of each of the selector parts
export const formatCss = (css) => {
  let parts = css.split(" > ");
  let processedParts = [];
  for (let i = 0; i < parts.length; i++) {
    let part = parts[i];
    if (part.indexOf("#") === 0) {
      // Transform #my-id to [id="my-id"]
      part = '[id="' + part.substring(1) + '"]';
    } else if (part.indexOf(".") !== -1) {
      // Sanitize class names
      part = formatCssClassNames(part);
    }
    processedParts.push(part);
  }
  css = processedParts.join(" > ");
  return css;
};

// Cleanup some css class names for querySelector
const formatCssClassNames = (part) => {
  let classNames = part.split(".");
  let processedClassNames = [];
  for (let j = 0; j < classNames.length; j++) {
    let className = classNames[j];
    if (className.match(/^\d/) || className.indexOf("@") >= 0) {
      // skip class names starting with number and contains @
    } else {
      className = className.trim().replace(/([^\s:]*):([^\s:]*)/g, "$1\\:$2");
      className = className.trim().replace(/([^\s]*)\/([^\s]*)/g, "$1\\/$2");
      className = className.trim().replace(/([^\s]*)\[([^\s]*)/g, "$1\\[$2");
      className = className.trim().replace(/([^\s]*)\]([^\s]*)/g, "$1\\]$2");
      className = className
        .trim()
        // eslint-disable-next-line no-useless-escape
        .replace(/([^\s]*)\!([^\s]*)/g, "$1\\!$2");
      processedClassNames.push(className);
    }
  }
  return processedClassNames.join(".");
};

// Recursive in case a shadow root is encountered
// Example : #shadowHostDiv > shadow-root > span
const customQuerySelector = (selector, root = document) => {
  const shadowRootIndicator = "> shadow-root >";
  const indexOfFirstShadowRoot = selector.indexOf(shadowRootIndicator);

  var lightDomSelector = selector;
  var remainingSelector = "";
  if (indexOfFirstShadowRoot !== -1) {
    lightDomSelector = selector.substring(0, indexOfFirstShadowRoot).trim();
    remainingSelector = selector
      .substring(indexOfFirstShadowRoot + shadowRootIndicator.length)
      .trim();
  }

  const lightDomElement = root.querySelector(lightDomSelector);

  if (lightDomElement && remainingSelector) {
    // If shadow DOM selector is provided, apply it to the shadow DOM
    const shadowRoot = lightDomElement.shadowRoot;

    if (shadowRoot) {
      // Recursive in case of nested shadow roots
      return customQuerySelector(remainingSelector, shadowRoot);
    }
  } else {
    return lightDomElement;
  }
};

/**
 * Secure selector to get the the first matching Element
 * @returns {(Element|null)}
 */
export const querySelector = (doc, query) => {
  let element = null;
  try {
    element = customQuerySelector(query, doc);
  } catch (err) {
    console.error("queryselector failed on ", query);
    Sentry.captureException(err);
  }
  return element;
};

// Recursive for shadow roots
export const querySelectorAll = (root, query) => {
  let elements = [];

  try {
    elements = Array.from(root.querySelectorAll(query));
    // Get element from all shadow roots

    const shadowRoots = getAllShadowRoots(root);
    shadowRoots.forEach((shadowRoot) => {
      const shadowElements = querySelectorAll(shadowRoot, query);
      elements = elements.concat(Array.from(shadowElements));
    });
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
  }

  return elements;
};

export const getAllShadowRoots = (root) => {
  let elements = [];
  let allElements = root.querySelectorAll("*");
  allElements.forEach((element) => {
    if (element.shadowRoot) {
      elements.push(element.shadowRoot);
      // Recursive
      let nestedElements = getAllShadowRoots(element.shadowRoot);
      elements = elements.concat(nestedElements);
    }
  });
  return elements;
};

/**
 * Select items smaller than the number of all items
 * @param {Number} numberOfAllItems
 * @returns {Array<{"label", "value"}>}
 */
export const filterItems = (numberOfAllItems) => {
  if (numberOfAllItems < 10) return [{ label: "All", value: "all" }];
  if (numberOfAllItems >= 10 && numberOfAllItems < 25)
    return [
      { label: "All", value: "all" },
      { label: "Top 10", value: "10" },
    ];
  if (numberOfAllItems >= 25 && numberOfAllItems < 50)
    return [
      { label: "All", value: "all" },
      { label: "Top 25", value: "25" },
      { label: "Top 10", value: "10" },
    ];
  else
    return [
      { label: "All", value: "all" },
      { label: "Top 50", value: "50" },
      { label: "Top 25", value: "25" },
      { label: "Top 10", value: "10" },
    ];
};

// Replay message page content
export const replayMessageContent = (liteMode, url, flag, iframeWidth) => {
  let title = !liteMode
    ? !flag
      ? DUMMY_TITLE_LITE_OFF
      : DUMMY_TITLE_LITE_OFF_INTERACTIONS
    : DUMMY_TITLE_LITE_ON;

  let paragraphText = !liteMode
    ? !flag
      ? DUMMY_PARAGRAPH_LITE_OFF
      : DUMMY_PARAGRAPH_LITE_OFF_INTERACTIONS
    : DUMMY_PARAGRAPH_LITE_ON;

  let mediaSmall = iframeWidth <= 576 ? true : false;

  return `
      <html>
        <head>
          <link
            href="https://fonts.googleapis.com/css?family=Nunito:200,400,700"
            type="text/css"
            rel="stylesheet"
          >
          <style>
            html, body {
              font-family: Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
              height: 100%;
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              text-align: center; 
              position: relative;
              font-size: ${mediaSmall ? "12px" : "16px"};
            }
            .logo {
              margin-bottom: 20px; 
              width: ${mediaSmall ? "72px" : "128px"}; 
              height: ${mediaSmall ? "72px" : "128px"}; 
            }
            h1 {
              margin-bottom: 15px; 
              font-size: ${mediaSmall ? "24px" : "48px"};
            }
            .footer {
              position: absolute;
              bottom: ${mediaSmall ? "8%" : "12%"};
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .footer:before,
            .footer:after {
              content: '';
              flex: 1;
              border-bottom: 1px solid;
              margin: 0 10px;
            }
          </style>
        </head>
        <body>
          <div class="content-container">
            <img src="/images/logo-app.png" alt="Insightech Logo" class="logo">
            <h1>${title}</h1>
            <p>${paragraphText}</p>
            <a href=${url} target="_blank">${url}</a>
            ${flag ? "" : `<p class="footer">${DUMMY_FOOTER}</p>`} 
          </div>
        </body>
      </html>`;
};

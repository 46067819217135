import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import "./style.scss";

// This component rendering with React Portal
function Modal(props) {
  const {
    title,
    titleIcon,
    footer,
    isVisible,
    handleClose,
    isBusy = false,
    width,
    className,
    handleSubmit,
  } = props;

  if (!isVisible) return null;

  return createPortal(
    <form
      className={`modal fade show ${className}`}
      tabIndex="-1"
      role="dialog"
      onSubmit={handleSubmit}
    >
      <div
        className="modal-dialog shadow"
        role="document"
        style={{ maxWidth: width }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">
              {title}
              <div className="title-icon">{titleIcon}</div>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
              disabled={isBusy}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{props.children}</div>
          {footer && <div className="modal-footer">{footer}</div>}
        </div>
      </div>
    </form>,
    document.getElementById("portal")
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  footer: PropTypes.node,
  isVisible: PropTypes.bool,
  handleClose: PropTypes.func,
};

Modal.defaultProps = {
  handleSubmit: (event) => event.preventDefault(), // Default to preventing submission
};

export default Modal;

import React from "react";
import "./style.scss";

function Collapsible({
  index,
  title,
  className,
  onClick,
  onMouseOver,
  onMouseOut,
  ...props
}) {
  return (
    <div
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
      className={`clickmap-card bg-gray-100 ${className}`}
      {...props}
    >
      <div className="clickmap-card-title">
        <div className="clickmap-card-index">{index}</div>
        <div className="clickmap-card-title-text">{title}</div>
      </div>
      <div className="clickmap-card-body">{props.children}</div>
    </div>
  );
}

Collapsible.propTypes = {};
Collapsible.defaultProps = {};

export default Collapsible;
